<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
				input: {},
				onEdit: 'N'
			}
		},
		computed: {
			mrValidation() {
				return this.$root.config.mrValidation.account_setting
			},
			isLogin() {
				return this.user && Gen.userToken() ? true : false
			},
			upic() {
				return this.user ? this.user.pic : false
			},
			ufullname() {
				return this.user ? this.user.fullname : "-"
			},
			uemail() {
				return this.user ? this.user.email : "-"
			},
		},
		async mounted() {
			await this.ready();
			this.$set(this.$root, 'page', this);
			if (typeof SEMICOLON != "undefined") SEMICOLON.documentOnReady.init()
			setTimeout(() => {
				$('[data-toggle="tooltip"]').tooltip();
				SEMICOLON.documentOnReady.init()
				SEMICOLON.slider.sliderParallax()
				SEMICOLON.widget.carousel()
			}, 100)
			$(document).ready(() => {
				AOS.init()
			})

			$(document).ready(function () {
				$('[data-toggle="popover"]').popover({
					trigger: 'hover',
					html: true,
					content: function () {
						return '<img class="img-fluid" src="' + $(this).data('img') + '" />';
					},
					title: 'Toolbox'
				})
			});
			$(document).on('click', '.mfp-close', () => {
				this.$router.push('/')
			});

			this.getUserData()
		},
		methods: {
			getUserData() {
				this.$root.topProgress.done()
				Gen.apirest("/get-user-data", {}, (err, resp) => {
					if (err) console.log(err)
					this.input = resp.data
				})
			},
			onSubmit(e) {
				if (e && e.btnLoading()) return;
				Gen.apirest("/submit-account-setting", this.input, (err, resp) => {
					if (e) e.btnUnloading()
					if (err && err.resp.description) return swal(err.resp.message, err.resp.description, "warning")
					if (err) return swal(err.resp.message, '', "warning")
					this.refreshVariableFo()
					this.getUserData()
					this.onEdit = 'N'
					swal(resp.message,'','success')
				})
			},
			goTop(target = '.info') {
				var offset = $(target + ":visible").offset();
				var topInfo = offset ? offset.top - 100 : 0;
				$("html,body").animate({
					scrollTop: -10
				}, 300);
			},
			getPostalArea(val) {
				if (!val) return
				let address = val.split(",")
				Gen.apirest("/select-postal?province=" + address[0] + "&city=" + address[1] + "&district=" + address[2] +
					"&sub_district=" + address[3], {}, (err, resp) => {
						if (err) console.log(err)
						if (!resp) return
						this.$set(this.input, "postal_code", (resp[0] || {}).postal_code)
					})
			},
			updateImage() {
				if (this.onEdit == 'Y') return this.onEdit = 'N'
				if (this.onEdit == 'N') return this.onEdit = 'Y'
			}
		},
		watch: {
			'input.full_address'(v) {
				this.getPostalArea(v)
			}
		}
	};
</script>
<template>
	<div id="content">
		<section class="section nobottommargin notopmargin wall-bg">
			<div class="container">
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><a href="dashboard.php">Dashboard</a></li>
					<li class="breadcrumb-item active">Account Setting</li>
				</ol>
				<div class="row">
					<div class="col-lg-4 col-md-5">
						<button @click="updateImage" class="btn-account-setting mt-2 ml-2"><i class="icon-settings"></i>
							{{onEdit == 'N'?'':'cancel'}}</button>
						<div class="box-el acc-info" v-if="onEdit=='N'">
							<img v-if="upic" :src="uploader(upic)" :alt="ufullname">
							<img v-else :src="assets('fo_images','user.png')" :alt="ufullname">
							<h4>{{ufullname}}</h4>
							<span>{{uemail}}</span>
						</div>
						<div class="box-el acc-info" v-if="onEdit=='Y'">
							<!-- <button @click="updateImage" class="btn-account-setting"><i class="icon-settings"></i>cancel</button> -->
							<Uploader name="bu_pic" type="fo_users" uploadType="cropping" :param="{thumbnail:true}"
								v-model="input.pic"></Uploader>
							<h4>{{ufullname}}</h4>
							<span>{{uemail}}</span>
						</div>
					</div>
					<div class="col-lg-8 col-md-7">
						<div class="box-el">
							<h3>{{web.lbl_account_setting}}</h3>
							<VForm @resp="onSubmit">
								<VInfo ref="info"></VInfo>
								<div class="form-row">
									<div class="col-md-6">
										<div class="form-group">
											<label for="">{{web.lbl_name}}</label>
											<input type="text" name="fullname" v-model="input.fullname"
												class="form-control" onkeydown="return fullNameKey(event)"
												:placeholder="web.lbl_name" v-bind="validation('fullname')">
											<LabelError name="fullname"></LabelError>
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group">
											<label for="">{{web.lbl_email}}</label>
											<input type="email" name="email" v-model="input.email"
												onkeydown="return emailKey(event)" class="form-control"
												v-bind="validation('email')" placeholder="example@mail.com">
											<LabelError name="email"></LabelError>
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group">
											<label for="">{{web.lbl_phone}}</label>
											<InputNumber type="PHONE" v-model="input.phone" class="form-control"
												placeholder="08xx-xxxx-xxxx"></InputNumber>
										</div>
									</div>
								</div>
								<h4>{{web.lbl_change_password}}</h4>
								<div class="form-row">
									<div class="col-md-6">
										<div class="form-group view_password">
											<label for="">{{web.lbl_new_password}}</label>
											<input type="password" name="password" id="password"
												v-model="input.password" v-bind="validation('password')"
												:placeholder="web.lbl_new_password" class="form-control">
											<div class="togglepass">
												<i class="btn_view icon-eye-close"></i>
											</div>
											<LabelError name="password"></LabelError>
										</div>
										<div class="form-group view_password">
											<label for="">{{web.lbl_confirm_password}}</label>
											<input type="password" name="confirm_password"
												:placeholder="web.lbl_confirm_password" v-model="input.confirm_password"
												v-bind="validation('confirm_password')" class="form-control">
											<div class="togglepass">
												<i class="btn_view icon-eye-close"></i>
											</div>
											<LabelError name="confirm_password"></LabelError>
										</div>
									</div>
								</div>
								<h4>{{web.lbl_address}}</h4>
								<div class="form-row">
									<div class="col-md-12">
										<div class="form-group">
											<label for="">{{web.lbl_full_address}}</label>
											<InputSuggest name="phone" v-model="input.full_address"
												:url="'/select-address?sub_district='+input.full_address"
												v-bind="validation('full_address')" :placeholder="web.lbl_full_address">
											</InputSuggest>
											<LabelError name="phone"></LabelError>
										</div>
									</div>
									<div class="col-md-9">
										<div class="form-group">
											<label for="">{{web.lbl_address}}</label>
											<textarea v-model="input.address" name="address" cols="30" rows="4"
												:placeholder="web.lbl_address" v-bind="validation('address')"
												class="form-control"></textarea>
											<LabelError name="address"></LabelError>
										</div>
									</div>
									<div class="col-md-3">
										<div class="form-group">
											<label for="">{{web.lbl_postal_area}}</label>
											<input name="text" v-model="input.postal_code"
												:placeholder="web.lbl_postal_area" readonly class="form-control">
										</div>
									</div>
								</div>
								<div class="text-right">
									<button type="submit"
										class="button button-red btn-loading">{{web.btn_submit}}</button>
								</div>
							</VForm>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>